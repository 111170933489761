.monthly-tiles * .fc-daygrid-day-events {
  padding: 0 7px 5px 7px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16px, 0.5fr));
  gap: 5px;
}

.weekly-tiles * .fc-daygrid-day-events {
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(2, minmax(16px, 0.5fr));
  gap: 5px;
}

.weekly-tiles * .fc-daygrid-day-events:before,
.monthly-tiles * .fc-daygrid-day-events:before {
  content: unset;
}
.weekly-tiles * .fc-daygrid-day-events:after,
.monthly-tiles * .fc-daygrid-day-events:after {
  content: unset;
}

.rescue-cancelled:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(
      to bottom left,
      transparent calc(50% - 3px),
      white calc(50% - 2px),
      red calc(50% - 1px),
      red calc(50% + 1px),
      white calc(50% + 2px),
      transparent calc(50% + 3px)
  ),
    linear-gradient(
      to bottom right,
      transparent calc(50% - 3px),
      white calc(50% - 2px),
      red calc(50% - 1px),
      red calc(50% + 1px),
      white calc(50% + 2px),
      transparent calc(50% + 3px)
  );
}

.rescue-cancelled-no_receiver:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(
      to bottom left,
      transparent calc(50% - 3px),
      white calc(50% - 2px),
      red calc(50% - 1px),
      red calc(50% + 1px),
      white calc(50% + 2px),
      transparent calc(50% + 3px)
    ),
    linear-gradient(
      to bottom right,
      transparent calc(50% - 3px),
      white calc(50% - 2px),
      #2196f3 calc(50% - 1px),
      #2196f3 calc(50% + 1px),
      white calc(50% + 2px),
      transparent calc(50% + 3px)
    );
}

.rescue-no_receiver:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: linear-gradient(
    to bottom right,
    transparent calc(50% - 3px),
    white calc(50% - 2px),
    #2196f3 calc(50% - 1px),
    #2196f3 calc(50% + 1px),
    white calc(50% + 2px),
    transparent calc(50% + 3px)
  );
}

.monthly-tiles * .fc-daygrid-day-events > .fc-daygrid-event-harness > .fc-daygrid-event {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.monthly-tiles * .fc-daygrid-day-events * .fc-daygrid-dot-event {
  margin: 1px;
}

.day-columns * .fc-daygrid-day-events > .fc-daygrid-event-harness > .fc-daygrid-event {
  margin-bottom: 4px;
}

/* agenda */
.fc-list-table * .fc-list-event {
  transition-property: background;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}
.fc-list-table * .fc-list-event.selected {
  background: #03a9f4;
  color: #fff;
}

.fc .fc-button {
  text-transform: capitalize;
}

.donation-calendar .fc-toolbar {
  display: flex;
  flex-direction: column;
}

.donation-calendar .fc-toolbar-chunk {
  width: 100%;
}

.donation-calendar .fc-toolbar-chunk:nth-child(3) {
  justify-content: flex-end;
}
